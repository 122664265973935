<template>
	<ConnectorServiceLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<SystemIntegrationsTab
						:lookup-data="systemIntegrationLookupData"
						:system-integrations="systemIntegrations"
						@loadSystemIntegrations="loadSystemIntegrations"
						@delete="onSystemIntegrationRemove"
						@change:active="onSystemIntegrationActiveChange"
					/>
				</template>
				<template #1>
					<IntegrationServicesTab
						:system-integration-items="systemIntegrationItems"
						:lookup-data="integrationServiceLookupData"
						:integration-services="integrationServices"
						@delete="onIntegrationServiceRemove"
						@change:active="onIntegrationServiceToBeChanged"
						@loadIntegrationServices="loadIntegrationServices"
					/>
				</template>
			</TabItems>
			<ConfirmDeleteModal
				v-if="!!systemIntegrationToBeRemoved"
				:entity-name="systemIntegrationToBeRemoved && systemIntegrationToBeRemoved.integrationSystemName"
				entity-type="System integration"
				@close="systemIntegrationToBeRemoved = null"
				@delete="onSystemIntegrationConfirmRemove"
			/>
			<ConfirmDeleteModal
				v-if="!!integrationServiceToBeRemoved"
				:entity-name="integrationServiceToBeRemoved && integrationServiceToBeRemoved.name"
				entity-type="Integration Service"
				@close="integrationServiceToBeRemoved = null"
				@delete="onIntegrationServiceConfirmRemove"
			/>
			<ConnectorServiceConfirmationModal
				v-if="systemIntegrationToBeChanged"
				:entity-name="systemIntegrationToBeChanged && systemIntegrationToBeChanged.integrationSystemName"
				:active="systemIntegrationToBeChanged && systemIntegrationToBeChanged.enabled"
				type="System Integration"
				@cancel="systemIntegrationToBeChanged = null"
				@confirm="onSystemIntegrationEnableDisable"
			/>
			<ConnectorServiceConfirmationModal
				v-if="integrationServiceToBeChanged"
				:entity-name="integrationServiceToBeChanged && integrationServiceToBeChanged.name"
				:active="integrationServiceToBeChanged && integrationServiceToBeChanged.isEnabled"
				type="Integration Service"
				@cancel="integrationServiceToBeChanged = null"
				@confirm="onIntegrationServiceEnableDisable"
			/>
		</template>
	</ConnectorServiceLayout>
</template>

<script>
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import SystemIntegrationsTab from './system-integrations-tab.vue'
import IntegrationServicesTab from './integration-services-tab.vue'
import { activeOptions, ActiveOptionIds } from './connector-service-options.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import ConnectorServiceConfirmationModal from './connector-service-confirmation-modal.vue'
import ConnectorServiceLayout from './connector-service-layout.vue'
import {
	getSystemIntegrations,
	getIntegrationServices,
	deleteSystemIntegration,
	deleteIntegrationService,
	enableDisableSystemIntegration,
	enableDisableIntegrationService
} from '../../../../../shared/utils/api/connector-service.js'
export default {
	components: {
		ConnectorServiceLayout,
		ConnectorServiceConfirmationModal,
		ConfirmDeleteModal,
		SystemIntegrationsTab,
		IntegrationServicesTab,
		TabItems,
		Tabs
	},
	props: {
		accessedVia: String
	},
	data () {
		const ACCESSED_VIA_INTEGRATION_SERVICES = 'integration-services'
		return {
			ACCESSED_VIA_INTEGRATION_SERVICES,
			tabs: [{ title: 'System integrations', slot: '0' }, { title: 'Integration services', slot: '1' }],
			tab: '0',
			searchQuery: '',
			isCreateEditModalShown: false,
			selectedBrandId: null,
			optionActiveId: ActiveOptionIds.all,
			activeOptions,
			systemIntegrations: [],
			systemIntegrationLookupData: null,
			integrationServices: [],
			integrationServiceLookupData: null,
			tableData: [],
			systemIntegrationToBeRemoved: null,
			systemIntegrationToBeChanged: null,
			integrationServiceToBeRemoved: null,
			integrationServiceToBeChanged: null
		}
	},
	computed: {
		systemIntegrationItems () {
			return this.systemIntegrations.map(({ id, integrationSystemName }) => ({
				text: integrationSystemName,
				value: id
			}))
		}
	},
	created () {
		this.loadSystemIntegrations()
		this.loadIntegrationServices()
		if (this.accessedVia === this.ACCESSED_VIA_INTEGRATION_SERVICES) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async loadSystemIntegrations () {
			const { data: { systemIntegrations, lookupData } } = await getSystemIntegrations()
			this.systemIntegrations = systemIntegrations
			this.systemIntegrationLookupData = lookupData
		},
		async loadIntegrationServices () {
			const { data: { integrationServices, lookupData } } = await getIntegrationServices()
			this.integrationServices = integrationServices
			this.integrationServiceLookupData = lookupData
		},
		onSystemIntegrationRemove (systemIntegration) {
			this.systemIntegrationToBeRemoved = systemIntegration
		},
		onIntegrationServiceRemove (integrationService) {
			this.integrationServiceToBeRemoved = integrationService
		},
		onSystemIntegrationActiveChange (systemIntegration) {
			this.systemIntegrationToBeChanged = systemIntegration
		},
		onIntegrationServiceToBeChanged (integrationService) {
			this.integrationServiceToBeChanged = integrationService
		},
		async onSystemIntegrationEnableDisable () {
			const systemIntegrationEnableDisable = {
				id: this.systemIntegrationToBeChanged.id,
				isEnabled: !this.systemIntegrationToBeChanged.enabled
			}
			await enableDisableSystemIntegration(systemIntegrationEnableDisable)
			showSnackbar(`System Integration has been ${this.systemIntegrationToBeChanged.enabled ? 'disabled' : 'enabled'}`)
			this.loadSystemIntegrations()
			this.systemIntegrationToBeChanged = null
		},
		async onIntegrationServiceEnableDisable () {
			const systemIntegrationEnableDisable = {
				id: this.integrationServiceToBeChanged.id,
				isEnabled: !this.integrationServiceToBeChanged.isEnabled
			}
			await enableDisableIntegrationService(systemIntegrationEnableDisable)
			showSnackbar(`System Integration has been ${this.integrationServiceToBeChanged.enabled ? 'disabled' : 'enabled'}`)
			this.loadIntegrationServices()
			this.integrationServiceToBeChanged = null
		},
		async onSystemIntegrationConfirmRemove () {
			await deleteSystemIntegration(this.systemIntegrationToBeRemoved.id)
			this.systemIntegrationToBeRemoved = null
			this.loadSystemIntegrations()
			showSnackbar('System integration has been removed.')
		},
		async onIntegrationServiceConfirmRemove () {
			await deleteIntegrationService(this.integrationServiceToBeRemoved.id)
			this.integrationServiceToBeRemoved = null
			this.loadIntegrationServices()
			showSnackbar('Integration Service has been removed.')
		}
	}
}
</script>
