<template>
	<Modal width="500px">
		<template #modal-title>
			{{ getActionTitle() }} {{ entityName }}
		</template>
		<template #modal-content>
			Are you sure you wish to {{ getActionTitle().toLowerCase() }} this {{ type }}?
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('cancel')">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton @click="$emit('confirm')">
				Confirm
			</PrimaryActionButton>
		</template>
	</modal>
</template>

<script>
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import Modal from '../../../../../shared/components/modal.vue'

export default {
	components: { Modal, SecondaryActionButton, PrimaryActionButton },
	props: {
		active: Boolean,
		entityName: String,
		type: String
	},
	methods: {
		getActionTitle () {
			return this.active ? 'Disable' : 'Enable'
		}
	}
}
</script>
