export const ActiveOptionIds = {
	all: null,
	active: true,
	disabled: false
}

export const activeOptions = [
	{ value: ActiveOptionIds.all, text: 'View All' },
	{ value: ActiveOptionIds.active, text: 'Active' },
	{ value: ActiveOptionIds.disabled, text: 'Disabled' }
]

export const AuthenticationIds = {
	none: 0,
	usernameAndPassword: 1,
	clientTokenAndSecret: 2,
	oAuth2: 3,
	jwt: 4
}

export const authenticationOptions = [
	{ value: AuthenticationIds.none, text: 'None' },
	{ value: AuthenticationIds.clientTokenAndSecret, text: 'Client Token & Secret' },
	{ value: AuthenticationIds.jwt, text: 'JWT' },
	{ value: AuthenticationIds.oAuth2, text: 'OAuth 2' },
	{ value: AuthenticationIds.usernameAndPassword, text: 'Username & Password' }
]

export const FrequencyIds = {
	oneMinute: 1,
	twoMinutes: 2,
	threeMinutes: 3,
	fourMinutes: 4,
	fiveMinutes: 5
}

export const frequenciesOptions = [
	{ value: FrequencyIds.oneMinute, text: '1 minute' },
	{ value: FrequencyIds.twoMinutes, text: '2 minutes' },
	{ value: FrequencyIds.threeMinutes, text: '3 minutes' },
	{ value: FrequencyIds.fourMinutes, text: '4 minutes' },
	{ value: FrequencyIds.fiveMinutes, text: '5 minutes' }
]
