import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getSystemIntegrations = () => configApi.get('/api/ConnectorService/SystemIntegrations').then(({ data }) => ({
	data: {
		systemIntegrations: data.systemIntegrations.map(systemIntegration => ({
			...systemIntegration,
			createdDate: format(new Date(systemIntegration.createdDate), DATE_FORMAT)
		})),
		lookupData: data.lookupData
	}
}))

export const postSystemIntegration = systemIntegration => {
	return configApi.post('/api/ConnectorService/SystemIntegration', systemIntegration)
}

export const deleteSystemIntegration = systemIntegrationId => configApi.delete(`/api/ConnectorService/SystemIntegration/${systemIntegrationId}`)

export const putSystemIntegration = systemIntegration => {
	return configApi.put('/api/ConnectorService/SystemIntegration', systemIntegration)
}

export const enableDisableSystemIntegration = systemIntegration => configApi.put('/api/ConnectorService/EnableDisableSystemIntegration', systemIntegration)

export const getIntegrationServices = () => configApi.get('/api/ConnectorService/IntegrationServices').then(({ data }) => ({
	data: {
		integrationServices: data.integrationServices.map(integrationService => ({
			...integrationService,
			objectSelectList: integrationService.objectSelectList.split(','),
			createdDate: format(new Date(integrationService.createdDate), DATE_FORMAT)
		})),
		lookupData: data.lookupData
	}
}))

export const postIntegrationService = integrationService => {
	return configApi.post('/api/ConnectorService/IntegrationService', integrationService)
}
export const putIntegrationService = integrationService => {
	return configApi.put('/api/ConnectorService/IntegrationService', integrationService)
}

export const deleteIntegrationService = integrationServiceId => configApi.delete(`/api/ConnectorService/IntegrationService/${integrationServiceId}`)

export const enableDisableIntegrationService = integrationService => configApi.put('/api/ConnectorService/EnableDisableIntegrationService', integrationService)
