<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="integrationServiceSearchQuery"
			search-query-label="Integration Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persist-search-query="changeIntegrationServiceSearchQuery"
			@persist-selected-brand-id="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="integrationServiceEnabledStatusFilter"
					:items="enabledStatusItems"
					custom-sort
					label="Enabled Status"
					@input="changeIntegrationServiceEnabledStatusFilter($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="onIntegrationServiceCreate"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard
			v-for="(integrationServiceGroup, index) in splitIntegrationServices"
			:key="index"
		>
			<template #title>
				{{ integrationServiceGroupCardTitle(integrationServiceGroup) }}
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="integrationServiceGroup"
					sort-by="id"
					sort-desc
					@click:row="onIntegrationServiceEdit"
				>
					<template #item.isEnabled="{ item }">
						{{ item.isEnabled ? 'Enabled' : 'Disabled' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							class="mr-2"
							:tooltip-text="userFullPermissions && item.isEditable ? 'Edit Integration Service' : 'View Integration Service'"
							@click="onIntegrationServiceEdit(item)"
						>
							{{ userFullPermissions ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="item.isEnabled && userFullPermissions"
							tooltip-text="Disable Integration Service"
							class="mr-2"
							@click.stop.prevent="$emit('change:active', item)"
						>
							mdi-pause-circle
						</IconButton>
						<IconButton
							v-else-if="!item.isEnabled && userFullPermissions"
							class="mr-2"
							tooltip-text="Enable Integration Service"
							@click.stop.prevent="$emit('change:active', item)"
						>
							mdi-play-circle
						</IconButton>
						<IconButton
							v-if="item.isEditable && userFullPermissions"
							class="mr-2"
							tooltip-text="Delete Integration Service"
							@click.stop.prevent="$emit('delete', item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataTable from '../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import { MANAGE_INTEGRATION_SERVICE } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { brandOptions, changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { CAN_SEE_CONNECTOR_SERVICE_MODULE, CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import {
	changeIntegrationServiceSearchQuery,
	changeIntegrationServiceEnabledStatusFilter,
	integrationServiceSearchQuery,
	integrationServiceEnabledStatusFilter
} from '../../../../../shared/state/connector-service.js'
export default {
	components: { SectionCard, DataTable, FilterBar, PrimaryActionButton, Dropdown, IconButton },
	props: {
		integrationServices: {
			type: Array,
			default: () => []
		},
		lookupData: Object,
		systemIntegrationItems: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			brandOptions,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeIntegrationServiceSearchQuery,
			changeIntegrationServiceEnabledStatusFilter,
			integrationServiceSearchQuery,
			integrationServiceEnabledStatusFilter
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			selectedStatus: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(CAN_SEE_CONNECTOR_SERVICE_MODULE)
		},
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '5%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '20%'
				},
				{
					text: 'Service Name',
					value: 'name',
					width: '25%'
				},
				{
					text: 'Sequence',
					value: 'processingSequence',
					width: '10%'
				},
				{
					text: 'Enabled',
					value: 'isEnabled',
					width: '`10%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '15%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '15%',
					sortable: false
				}
			]
		},
		filteredIntegrationServices () {
			return this.integrationServices.filter(({ name, brandId, isEnabled }) => {
				let check = true
				if (integrationServiceSearchQuery.value) check = name.toLowerCase().includes(integrationServiceSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				if (integrationServiceEnabledStatusFilter.value !== null) check = check && integrationServiceEnabledStatusFilter.value === isEnabled
				return check
			}).map(integrationName => ({
				...integrationName,
				brandName: brandOptions.value.find(({ value }) => integrationName.brandId === value)?.text
			}))
		},
		enabledStatusItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Enabled',
					value: true
				},
				{
					text: 'Disabled',
					value: false
				}
			]
		},
		splitIntegrationServices () {
			const splitIntegrationServices = this.filteredIntegrationServices.reduce((acc, integrationService) => {
				if (!acc[integrationService.integrationId]) {
					acc[integrationService.integrationId] = []
				}

				acc[integrationService.integrationId].push(integrationService)
				return acc
			}, {})
			return splitIntegrationServices
		}
	},
	methods: {
		integrationServiceGroupCardTitle (integrationServiceGroup) {
			return this.systemIntegrationItems.find(({ value }) => value === integrationServiceGroup[0].integrationId).text
		},
		onIntegrationServiceCreate () {
			this.$router.push({ name: MANAGE_INTEGRATION_SERVICE, params: { lookupData: this.lookupData, systemIntegrationItems: this.systemIntegrationItems } })
		},
		onIntegrationServiceEdit (integrationServiceToEdit) {
			this.$router.push({
				name: MANAGE_INTEGRATION_SERVICE,
				params: { integrationServiceToEdit, lookupData: this.lookupData, systemIntegrationItems: this.systemIntegrationItems }
			})
		},
		clearFilters () {
			changeIntegrationServiceEnabledStatusFilter(null)
		}
	}
}
</script>
